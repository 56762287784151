@import "_tools.scss";

/*
 * Bandeau deconnection
 */
.logout-notification {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	transform: translateY(-100px);

	align-items: center;
	background: #fefaf7;
	border: 0;
	border-radius: 0;
	box-shadow: 0 rem(2px) rem(3px) 0 rgba(0, 0, 0, .35);
	color: #000;
	display: flex;
	font-size: rem(16px);
	height: rem(50px);
	margin: 0;
	text-align: center;
	justify-content: center;
	width: 100%;
	z-index: 2;

	animation-name: notification;
	animation-duration: 4s;
	animation-iteration-count: 1;
}

@keyframes notification {
    0%   { transform: translateY(-100px); }
    10%  { transform: translateY(0px); }
    70%  { transform: translateY(0px); }
    100% { transform: translateY(-100px); }
}

/*
 * Bandeau listes visitées
 */
.visited-notification {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;

	align-items: center;
	background: #fefaf7;
	border: 0;
	border-radius: 0;
	box-shadow: 0 rem(2px) rem(3px) 0 rgba(0, 0, 0, .35);
	color: #000;
	display: flex;
	font-size: rem(16px);
	min-height: rem(50px);
	margin: 0;
	text-align: center;
	justify-content: center;
	width: 100%;
	z-index: 2;

	animation-name: visited-notification;
	animation-duration: 1s;
	animation-iteration-count: 1;

	p {
		font-size: rem(14px);
		margin-bottom: 0;
		padding: rem(10px) rem(40px);
		width: 100%;

		a {
			display: inline-block;
		}
	}

	button {
		background: #FFF;
		border: 1px solid #444;
		border-radius: 5px;
		color: #333;
		cursor: pointer;
		display: block;
		font-size: rem(18px);
		height: rem(22px);
		line-height: 0;
		padding: 0;
		position: fixed;
		right: rem(10px);
		text-align: center;
		top: rem(15px);
		width: rem(22px);

		&:hover {
			border-color: #c43c3c;
			color: #c43c3c;
		}

		&:active {
			transform: translateY(2px);
		}
	}
}

@keyframes visited-notification {
    0%   { transform: translateY(-100px); }
    100%  { transform: translateY(0px); }
}

.notification--dismiss {
  animation-name: notification-dismiss;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

@keyframes notification-dismiss {
    0%   { transform: translateY(0px); }
    100%  { transform: translateY(-100px); }
}

.visited-notification + .homepage-header .content .home-header__connect {
	top: rem(60px);
}

/*
 * Header
 */
.homepage {
  background: #ffffff;
  background: #ffffff url(../../images/home/bg.svg) repeat-y center top / cover;

  &:after {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;

    content: '';
    display: block;
    height: rem(100px);
    background: linear-gradient(to bottom, rgba(255,255,255,0), #FFF);
  }

  .page {
    @media only screen and (min-width: rem(851px)) {
      background: url(../../images/home/snake.png) no-repeat center top;
    }

    @media only screen and (max-width: rem(1170px)) {
      background-size: rem(1000px) auto;
    }

		@media only screen and (max-width: rem(1000px)) {
			background-size: rem(850px) auto;
		}

		@media only screen and (min-width: rem(1171px)) and (max-width: rem(1400px)) {
			background-size: 100% auto;
		}
  }
}

.homepage-header {
  position: relative;

	.nav.isConnected {
		height: rem(50px);
	}

  .content {
    position: unset;


    @media only screen and (max-width: rem(767px)) {
      background: $main-color;
      padding: 0;

			.nav {
				padding: 0 rem(16px);
			}
    }
  }

  &:after {
  	content: '';
  	display: block;
  	height: rem(500px);
  	background: #f96060 url(../../images/home/banner-1024w.jpg) no-repeat center top;
  	border-bottom: rem(4px) solid $main-color;

		@media only screen and (max-width: rem(480px)) {
			height: auto;
			padding-bottom: calc(70vw + #{rem(10px)});
			background-image: url(../../images/home/banner-480w.jpg);
			background-size: 100% auto;
			background-position: bottom center;
		}

		@media only screen and (min-width: rem(481px)) and (max-width: rem(600px)) {
			height: auto;
			padding-bottom: rem(350px);
			background-image: url(../../images/home/banner-600w.jpg);
			background-position: right bottom;
		}

		@media only screen and (min-width: rem(601px)) and (max-width: rem(768px)) {
			height: auto;
			padding-bottom: rem(460px);
			background-image: url(../../images/home/banner-768w.jpg);
			background-position: right bottom;
		}


		@media only screen and (min-width: rem(769px)) and (max-width: rem(1024px)) {
			height: auto;
			padding-bottom: rem(460px);
			background-image: url(../../images/home/banner-1024w.jpg);
		}

  	@media only screen and (min-width: rem(1025px)) and (max-width: rem(2047px)) {
  		height: auto;
  		padding-bottom: rem(500px);
  		background-image: url(../../images/home/banner-2048w.jpg);
  	}

		@media only screen and (min-width : rem(2048px)) {
			height: auto;
			padding-bottom: rem(500px);
			background-image: url(../../images/home/banner-4096w.jpg);
		}
  }

  .home-header__connect {
    position: absolute;
    right: calc(50% - #{rem(600px)});
    top: rem(8px);
    z-index: 1;

    @media only screen and (max-width: rem(1216px)) {
      right: rem(10px);
    }
  }
}

.home-header__edito {
  position: absolute;
  left: calc(50% - #{rem(595px)});
  top: rem(155px);

  color: #FFF;
  font-size: rem(17px);

  .logo {
		margin: 0 auto;

		img {
	    height: auto;
	    margin-bottom: rem(30px);
	    width: rem(265px);
	  }
	}

  .button {
    margin: rem(20px) 0;
  }

	.button--large {
		border-radius: rem(25px);
		color: #592929;
		height: rem(50px);
		line-height: rem(30px);

		&:hover {
			background: #5d4140;
			border-color: #5d4140;
			color: #FFF;
		}

		@media only screen and (max-width: rem(480px)) {
			font-size: rem(16px);
		}
	}

	.home-header__example {
		&:hover {
			color: #fff;
		}

		@media only screen and (max-width: rem(480px)) {
			font-weight: 500;
		}
	}


	@media only screen and (max-width: rem(600px)) {
		& {
			position: relative;
			left: inherit !important;
			top: inherit;

			text-align: center;
			margin: 0;

			.logo img {
				margin: 0 auto;
				padding: rem(45px) 0 rem(30px);
				width: rem(265px);
			}
		}
	}

  @media only screen and (max-width: rem(1216px)) {
    left: rem(15px);
  }
}


.homepage-header {
	.home-header__connect + .home-header__edito {
		top: rem(100px);

		@media only screen and (max-width: rem(480px)) {
			top: rem(0px);
		}

		@media only screen and (max-width: rem(767px)) {
			top: rem(68px);
		}

		.logo {
			padding-top: rem(32px);
		}
	}
}



/*
 * Comment ça marche ?
 */
.howto {
  text-align: center;

  .howto-title {
    color: $main-color;
    font-size: rem(36px);
    font-weight: lighter;
    text-align: center;

    &:before {
      background: url(../../images/home/knot.png) no-repeat center center;
      background-size: 100%;
      content: '';
      display: block;
      height: rem(18px);
      margin: rem(16px) auto 0;
      width: rem(26px);
    }
  }

  .box-container {
    transition: all .2s cubic-bezier(.19, 1.09, .83, .99);
    width: 100%;
  }

  .box {
    display: block;
    font-size: 0;
    padding: rem(12px);
    position: relative;
    width: rem(452px);
    transition: all .2s cubic-bezier(.19, 1.09, .83, .99);
  }

  .howto-phylactery {
    background-size: auto 100% !important;
    display: block;
    height: rem(90px);
    position: absolute;
    text-indent: rem(-99999px);
    transition: all .2s cubic-bezier(.19, 1.09, .83, .99);
    width: rem(260px);
  }

  /* Steps */
  .howto-step1 {
    margin: rem(110px) 0 0 rem(70px);

    .howto-phylactery {
      background: url(../../images/home/phylactery-01.png) no-repeat left top;
      left: rem(-35px);
      top: rem(-70px);
    }
  }

  .howto-step2 {
    margin: rem(-270px) 0 0 rem(635px);

    .howto-phylactery {
      background: url(../../images/home/phylactery-02.png) no-repeat left top;
      right: rem(-60px);
      top: rem(-35px);
    }
  }

  .howto-step3 {
    margin: rem(235px) 0 0 rem(15px);

    .howto-phylactery {
      background: url(../../images/home/phylactery-03.png) no-repeat left top;
      left: rem(75px);
      top: rem(-35px);
    }
  }

  .howto-step4 {
    margin: rem(-230px) 0 0 rem(570px);

    .howto-phylactery {
      background: url(../../images/home/phylactery-04.png) no-repeat left top;
      right: rem(-70px);
      top: rem(35px);
    }
  }
}

@media only screen and (max-width: rem(1000px)) {
  .howto {
    .box-container {
      transform: scale(0.7);
      margin-bottom: rem(-70px);
      margin-left: calc(50% - #{rem(530px)});
      margin-top: rem(-140px);
    }

		.howto-step2 {
      margin-top: rem(-200px);
    }

		.howto-step4 {
      margin-top: rem(-320px);
    }
  }
}

@media only screen and (max-width: rem(1170px)) {
  .howto {
    .box-container {
      transform: scale(0.86);
      margin-bottom: rem(-70px);
      margin-left: calc(50% - #{rem(565px)});
      margin-top: rem(-60px);
    }

		.howto-step3 {
      margin-top: rem(100px);
    }
  }
}

@media only screen and (max-width: rem(850px)) {
  .howto {
    .box-container {
      transform: inherit;
			margin-bottom: 0;
      margin-left: 0;
      margin-top: rem(100px);

      .box {
        display: block;
        margin: rem(75px) auto !important;
      }
    }
  }
}


@media only screen and (max-width: rem(590px)) {
  .howto {
    .box-container {
      padding: 0 8.9%;
			margin-top: 0;

      .box {
        max-width: 100%;
        width: 100%;

        img {
          width: 100%;
          height: auto;
        }

        .howto-phylactery {
          transform: scale(.7);
        }
      }

      .howto-step3 .howto-phylactery {
        left: inherit;
        right: rem(50px);
      }

      .howto-step4 .howto-phylactery {
        top: 0;
      }
    }
  }
}


/*
 * Bottom
 */
.home-bottom {
  margin: 8vw 0;
  padding: 0;

  .home-description {
    color: #333;
    font-size: rem(16px);
		line-height: rem(20px);
		margin-top: rem(32px);
  }

  .home-description-title {
    color: $main-color;
    display: block;
    font-size: rem(18px);
		line-height: rem(32px);
  }

  .grid-column:before {
    background-size: auto 100% !important;
    content: '';
    display: block;
    height: rem(25px);
    width: rem(25px);
  }

  .grid-column--multisite:before  { background: url(../../images/home/icons.png) no-repeat -0px  top; }
  .grid-column--subscribe:before  { background: url(../../images/home/icons.png) no-repeat -25px top; }
  .grid-column--responsive:before { background: url(../../images/home/icons.png) no-repeat -50px top; }
  .grid-column--private:before    { background: url(../../images/home/icons.png) no-repeat -75px top; }

	.grid-column {
		@media only screen and (min-width: rem(480px)) and (max-width: rem(600px)) {
      margin: 0 rem(32px);
    }

    @media only screen and (min-width: rem(600px)) {
      width: calc(50% - #{$gutter * 5});
			margin: calc(#{$gutter * 2.5});
    }

    @media only screen and (min-width: rem(1024px)) {
			width: calc(25% - #{$gutter * 5});
			margin: calc(#{$gutter * 2.5});

			.home-description {
				margin-top: 0;
			}
		}
  }

	@media only screen and (min-width: rem(600px)) and (max-width: rem(768px)) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-start;
		align-content: flex-start;
	}
}
